import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Loading from "../Loading";
import Fade from "react-reveal/Fade";

const HomeJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch("https://right-job.glitch.me/Jobs")
      .then((res) => res.json())
      .then((data) => {
        setJobs(data);
        setLoading(true);
      });
  }, []);

  return (
    <div className="mx-0" style={{marginTop: '60px'}}>
      <div className="line"></div>
      <h5
        style={{
          color: "#262339",
          fontFamily: "Alata",
          display: "inline-block",

        }}
        className="fw-bold mx-1"
      >
        Most Recent Jobs
      </h5>
      <div className="line"></div>

      <div className="row mx-0 me-2 mt-3 mt-lg-4 gap-5 align-items-center justify-content-center">
        {loading ? (
          jobs.slice(Math.max(jobs.length - 3, 0)).map((displayjob) => (
            <Fade key={displayjob._id}>
            <div
              className="col-lg-3 col-md-4 col-sm-6 col-11 mb-3 card p-0 justify-content-center ms-2 ms-lg-0 rounded-0"
            >
              <div className="row justify-content-center">
                <div className="col-lg-12 row justify-content-start">
                  <h6
                    style={{
                      borderBottom: "1px solid lightgray",
                      borderRight: "1px solid lightgray",
                      fontSize: '13px'
                    }}
                    className="py-1 mb-2 px-0 col-lg-5 col-5 text-danger"
                  >
                    {displayjob?.type}
                  </h6>
                </div>
                <div className="col-lg-12 mt-3 mt-lg-2">
                  <img
                    src="https://i.ibb.co.com/0tyXNvc/jv.png"
                    className="img-fluid"
                    style={{height: '200px'}}
                    alt="..."
                  />
                </div>
                <div className="col-lg-9 text-center">
                  <h5
                    style={{ letterSpacing: '1px', fontFamily: "Lexend", textShadow: '1px 1px 2px #000' }}
                    className="text-primary fw-bold my-3"
                  >
                    {displayjob?.title}
                  </h5>
                  <p style={{fontWeight: '600', color: '#11B719'}} className="mb-3">
                    <i className="fas fa-map-marker-alt"></i> {displayjob?.location}
                  </p>
                  <p className="mb-4 fw-bolder"> Vacancy : 
                     <span className="text-danger ms-1"> {displayjob?.vacancy} </span>
                    </p>
                  <button className="mb-4 btn btn-dark rounded-0 py-1">
                    <Link
                      style={{ color: "white", fontFamily: "Amita", wordSpacing: '5px'}}
                      className="text-decoration-none"
                      to={`/Job/${displayjob._id}`}
                    >
                      Browse Job &#8594;
                    </Link>
                  </button>
                </div>
              </div>
            </div>
              </Fade>
            
          ))
        ) : (
          <Loading></Loading>
        )}
        <Link to="/Jobs">
          <button style={{ backgroundColor: '#0094B7', fontWeight: '600' }} className='btn text-light rounded-0'>Browse All <i className="far fa-hand-point-right ms-1"></i></button>
        </Link>
      </div>
    </div>
  );
};

export default HomeJobs;
