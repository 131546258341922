import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div
      className="pt-4 pb-1 text-light row justify-content-center mx-0"
      style={{ backgroundColor: "#2C2D2D", marginTop: "2px" }}
    >
      <div style={{ letterSpacing: "2px" }} className="col-lg-7 text-start">
        <h5
          style={{ fontFamily: "Lexend", fontWeight: "bolder" }}
          className="mb-3"
        >
          <img style={{width: '100px'}} src="img/jobchaai.png" alt="" />
        </h5>
        <p>Smile 2 work</p>

        <p>Address: 43, 1st Floor, Mile End Road, <br/>
London, E1 4TT, United Kingdom</p>
        <p>Email: info@jobchaai.com </p>
        <p>Call: +44 7398519679</p>
        <div className="mt-4">
          <a
            className="me-2 fs-3"
            target="blank"
            href="https://www.facebook.com"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            className="mx-2 fs-3"
            target="blank"
            href="https://www.instagram.com"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a className="mx-2 fs-3" target="blank" href="https://twitter.com">
            <i className="fab fa-twitter"></i>
          </a>
          <a
            className="mx-2 fs-3"
            target="blank"
            href="https://www.youtube.com"
          >
            <i className="fab fa-youtube"></i>
          </a>
        </div>
      </div>

      <div className="col-lg-3 text-start mt-5 pt-0 pt-lg-5">
        <h4 style={{ fontFamily: "Amita" }} className="fw-bold mb-3 mt-1">
          Quick Links
        </h4>
        <p style={{ letterSpacing: "2px" }}>
          <Link className="ms-2 text-light text-decoration-none" to="/Jobs">
            Jobs
          </Link>
        </p>
        <p style={{ letterSpacing: "2px" }}>
          <Link className="ms-2 text-light text-decoration-none" to="/Contact">
            Contact
          </Link>
        </p>
        <p style={{ letterSpacing: "2px" }}>
          <Link className="ms-2 text-light text-decoration-none" to="/Reviews">
            Reviews
          </Link>
        </p>
      </div>
      <div
        style={{ backgroundColor: "#607831" }}
        className="col-lg-12 row justify-content-center mt-5 pt-4 pb-2 mx-0"
      >
        <div className="col-lg-2 col-6">
          <p>Privacy Policy</p>
        </div>
        <div className="col-lg-2 col-6">
          <p>Terms of Conditions</p>
        </div>
        <div className="col-lg-2 col-6">
          <p>Contact</p>
        </div>
        <div className="col-lg-5">
          <p style={{ fontFamily: "monospace" }}>
            Copyright © www.jobchaai.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
