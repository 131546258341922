import Button from "@restart/ui/esm/Button";
import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import "./nav.css";

const ResponsiveNav = () => {
  const { logout, user, admin, employer } = useAuth();

  return (
    <div>
      <Navbar style={{ borderBottom: '2px solid gray' }} className="N" collapseOnSelect expand="lg">
        <Navbar.Brand
          style={{ color: "#2F5C8A", fontFamily: "Lexend" }}
          className="fw-bolder text-primary ms-lg-5"
        >
          <Nav.Link as={Link} to="/Home">
            <img className="logo" src="img/jobchaai.png" alt="" />
          </Nav.Link>

        </Navbar.Brand>

        <Navbar.Toggle style={{ marginRight: '15px' }} aria-controls="basic-navbar-nav" />

        <Navbar.Collapse className="justify-content-center">
          <Nav.Link className="link" as={Link} to="/Home">
            HOME
          </Nav.Link>
          <Nav.Link className="link" as={Link} to="/Jobs">
            JOBS
          </Nav.Link>
          <Nav.Link className="link" as={Link} to="/Reviews">
            REVIEWS
          </Nav.Link>
          <Nav.Link className="link" as={Link} to="/Contact">
            CONTACT
          </Nav.Link>

          {admin && (
            <>
              <Nav.Link className="link" as={Link} to="/Candidate">
                CANDIDATES
              </Nav.Link>
              <Nav.Link className="link" as={Link} to="/AddJob">
                Add Job
              </Nav.Link>
              <Nav.Link className="link" as={Link} to="/AddBanner">
                Add Banner
              </Nav.Link>
            </>
          )}
          {employer && (
            <>
              <Nav.Link className="link" as={Link} to="/Candidate">
                CANDIDATES
              </Nav.Link>
            </>
          )}

          {user?.email ? (
            <Button
              className="btn rounded-4 ms-lg-4 mb-3 mb-lg-0 mt-3 mt-lg-0"
              style={{
                backgroundColor: "#DC3545",
                color: "white",
                marginRight: '15px',
                padding: "4px 16px 5px 16px",
                paddingTop: "2px",
              }}
              onClick={logout}
              variant="light"
            >
              Logout
            </Button>
          ) : (
            <Nav.Link
              as={Link}
              to="/Login"
              className="btn rounded-4 ms-lg-4 mt-3 mt-lg-0"
              style={{
                backgroundColor: "royalblue",
                color: "white",
                marginRight: '15px',
                padding: "4px 16px 5px 16px",
                paddingTop: "2px",
              }}
              variant="light"
            >
              Login
            </Nav.Link>
          )}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default ResponsiveNav;
