import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ApplyModal from "../Apply/ApplyModal";
import MyComponent from "./MyComponent";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";


const Job = () => {
  const [job, setJob] = useState([]);
  const { JobId } = useParams();
  const { user, admin } = useAuth();
  const [openApply, setApplyOpen] = React.useState(false);
  const handleApplyOpen = () => setApplyOpen(true);
  const handleApplyClose = () => setApplyOpen(false);
  const dynamicData = job?.description;

  useEffect(() => {
    fetch(`https://right-job.glitch.me/Jobs/${JobId}`)
      .then((res) => res.json())
      .then((data) => setJob(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ScrollToTop = () => {
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, []);

    return null;
  };

  return (
    <div className="mb-5 text-start">
      <div
        className="row align-items-center justify-content-start mx-0 px-0"
        style={{
          minHeight: "50vh",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `linear-gradient(rgba(5, 10, 30, 0.6), rgba(5, 10, 30, 0.8)), url()`,
        }}
      >
        <div
          className="col-lg-3 ms-5 d-lg-block d-none"
          style={{
            position: "relative",
            backgroundColor: "white",
            borderRadius: "50%",
            height: "140px",
            width: "140px",
          }}
        >
          <img
            style={{ width: "105px", marginLeft: "7px", marginTop: "15px" }}
            src='/img/gg.png'
            alt=""
          />
        </div>
        <p
          style={{ fontFamily: "Lexend" }}
          className="col-lg-6 text-light fs-3 ms-lg-3 mt-3"
        >
          <i
            style={{ marginLeft: "-2px" }}
            className="fas fa-user-tie fs-5 d-inline d-lg-none"
          ></i>{" "}
          {job?.title}
          <p style={{ fontSize: "18px", marginTop: "8px" }}>
            <i className="fas fa-map-marker-alt"></i> {job?.location}
          </p>
          <p style={{ fontSize: "18px", marginTop: "-2px" }}>
            <i className="fas fa-calendar-check"></i> Application Ends: {job?.end}
          </p>
        </p>
      </div>

      <div className="row justify-content-around mx-0 my-5 px-0">
        <div className="col-lg-6 mb-5">
          <ApplyModal
            job={job}
            openApply={openApply}
            handleApplyClose={handleApplyClose}
          ></ApplyModal>

          {user.email || admin ? (
            <button
              className="btn btn-primary mb-4 rounded-0"
              style={{ border: "3px solid #69A2F5" }}
              onClick={handleApplyOpen}
              variant="contained"
            >
              Apply Now
            </button>
          ) : (
            <div>
              <Nav.Link
                as={Link}
                to="/Login"
              >
                <button
                  className="btn btn-primary mb-4 rounded-0"
                  variant="contained"
                >
                  Apply Now
                </button>
              </Nav.Link>
              
            </div>
          )}

          <h2
            style={{
              color: "#272F46",
              fontWeight: "600",
              marginBottom: "25px",
            }}
          >
            Responsibities
          </h2>
          <MyComponent data={dynamicData} />
        </div>
        {job.title ? (
          <div
            style={{
              border: "1px solid lightgray",
              height: "700px",
              fontSize: "13px",
            }}
            className="col-lg-4 col-11 row mx-0 px-0 justify-content-center job"
          >
            <h4 className="text-center bg-secondary mb-5 pt-3 text-light fw-bold">
              Job Overview
            </h4>

            <div className="col-lg-4 col-4 pe-0" style={{ fontWeight: "700" }}>
              <p>Type :</p>
              <hr />
              <p>Job Title :</p>
              <hr />
              <p>Vacancy :</p>
              <hr />
              <p>Location :</p>
              {job?.location === undefined
                ? ""
                : job?.location.length > 35 && <br />}
              <hr style={{ marginTop: "0px" }} />

              <p>Gender :</p>
              <hr />
              <p>industry :</p>
              <hr />
              <p>Education:</p>
              <hr />
              <p>Course:</p>
              <hr />
              <p>Experience:</p>
              <hr />
              <p>Salary:</p>
              <hr />
              <p>End Date :</p>
            </div>

            <div
              className="col-lg-7 col-8 ps-1"
              style={{ fontFamily: "monospace", marginLeft: "-4px" }}
            >
              <p>{job?.type}</p>
              <hr />
              <p>{job?.title}</p>
              <hr />
              <p>{job?.vacancy}</p>
              <hr />
              <p>{job?.location}</p>
              <hr />
              <p>{job?.gender}</p>
              <hr />
              <p>{job?.industry}</p>
              <hr />
              <p>{job?.qualification}</p>
              <hr />
              {job?.course ? <p>{job?.course}</p> : "Not required"}
              <hr />
              <p>{job?.experience}</p>
              <hr />
              <p>{job?.salary}</p>
              <hr />
              <p>{job?.end}</p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <ScrollToTop />
    </div>
  );
};

export default Job;
