import React, { useEffect } from "react";

const Contact = () => {

  const ScrollToTop = () => {
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, []);

    return null;
  };

  return (
    <div style={{ marginBottom: "100px" }}>
      <div
        className="gg"
        style={{
          marginTop: "-48px",
          backgroundImage: `linear-gradient(rgba(5, 10, 30, 0.6), rgba(5, 10, 30, 0.6)),url(img/contact.jpg)`,
        }}
      >
        <p className="text-start fw-bold text-light mt-5 pt-5 ms-lg-5 ps-lg-5 ps-3">
          Home &#187; Contact
        </p>
        <h2 className="text-start fw-bold text-light ms-lg-5 ps-lg-5 mt-lg-4 ps-3">
          Contact & About Us
        </h2>
      </div>
      <div className="container text-start mt-5">
        <h2 className="fw-bold mb-3">About Us</h2>
        <p
          style={{
            fontFamily: "Lexend",
            wordSpacing: "1px",
            lineHeight: "30px",
          }}
        >
          What is Job Chaai ? JobChaai is a UK recruitment agency, specializes in
          connecting talented professionals with employers seeking the perfect
          fit. Their extensive database features a wide range of positions
          across various industries, ensuring there's something for everyone.
          Job seekers can easily navigate listings by location, company, or
          field, and receive personalized alerts for relevant opportunities.
          JobChaai empowers individuals by offering guidance on crafting strong
          CVs and applications, maximizing their chances of landing their dream
          job.
        </p>
        <hr className="mt-5"/>
      </div>
      <div className="row justify-content-center align-items-center mx-lg-5 mx-1 my-5">
      <div
          style={{ fontFamily: "Itim", wordSpacing: "3px"}}
          className="col-lg-5 text-lg-start px-3"
        >
          <p>
            <i className="fas fa-map-marker-alt"></i> 43, 1st Floor, Mile End
            Road, London, E1 4TT, United Kingdom
          </p>
          <p>
            <i className="fas fa-phone-alt"></i> +44 7398519679
          </p>
          <p>
            <i className="fas fa-envelope"></i> info@rightjob.co.uk
          </p>
        </div>
        <div className="col-lg-5">
          <img className="w-100" src="img/contact.png" alt="" />
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

export default Contact;
